.NotFound {
  padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    padding: calc(var(--spacing-unit) * 2);
    align-items: center;
    flex-direction: row;
  }
  .imageWrapper {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
    @media (min-width: 768px) {
      width: 55%;
    }
  }
  .content-wrapper {
    width: 100%;
    h1,
    h2 {
      color: var(--primary);
    }
    a {
      color: var(--inherit);
      text-decoration: underline;
      &:hover {
        color: var(--orange);
      }
    }
    @media (min-width: 768px) {
      padding: calc(var(--spacing-unit) * 2);
      width: 45%;
    }
  }
}
